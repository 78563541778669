import {FETCH_ADMINS,UPDATE_ADMIN,FETCH_ACTIVITIES,FETCH_ACTIVITY_SNAPSHOT,
  FETCH_ROLE_PERMISSIONS,GET_ROLES,GET_GROUPS,
  FETCH_CHALLENGES, FETCH_CHALLENGE_CATEGORIES, FETCH_CHALLENGES_FOR_USER  
} from "../types/adminTypes";
import { LOGOUT_USER, ENV_CHANGE, NETWORK_ERROR_OCCURRED } from "../types/authTypes";
   
   const INITIAL_STATE = {
     data: [],
     total: 0,
     activities: [],
     page_info: {},
     snapshots: [],
     networkError: false,
     roles: [],
     permissions: {},
     groups: [],
     challenges: [],
     challenge_categories: [],
     user_challenge_progress: []
   };
   
   // eslint-disable-next-line import/no-anonymous-default-export
   export default (state = INITIAL_STATE, action) => {
    const updateAdmin = (state, action) => {
      const adminId = action.payload.id;
  
      const newAdminList = state.data.slice(0);
      const AdminIndex = newAdminList.findIndex(admin => admin.id === adminId);
  
      newAdminList[AdminIndex] = action.payload;
      return newAdminList;
    };
  
     switch (action.type) {
       case FETCH_ADMINS:
          return {
              ...state,
              data: action.payload,
              total: action.total,
          }
        case UPDATE_ADMIN:
          return {
            ...state,
            data: updateAdmin(state,action)
          }
        case FETCH_ACTIVITIES:
          return {
            ...state,
            activities: action.payload,
            page_info: action.page_info
          }
        case FETCH_ACTIVITY_SNAPSHOT:
          return {
            ...state,
            snapshots: action.payload,
          }
        case NETWORK_ERROR_OCCURRED:
          return {
            ...state,
            networkError: true,
          };
        case GET_ROLES:
          return {
            ...state,
            roles: action.payload,
          };
        case FETCH_ROLE_PERMISSIONS:
          return {
            ...state,
            permissions: {
              ...state.permissions,
              [action.payload.roleId]: action.payload.data,
            },
          };
        case GET_GROUPS:
          return {
            ...state,
            groups: action.payload,
          };
        case FETCH_CHALLENGES:
          return {
            ...state,
            challenges: action.payload,
          };
        case FETCH_CHALLENGE_CATEGORIES:
          return {
            ...state,
            challenge_categories: action.payload,
          };
        case FETCH_CHALLENGES_FOR_USER:
          return {
            ...state,
            user_challenge_progress: action.payload,
          };
        case ENV_CHANGE:
        case LOGOUT_USER:
          return INITIAL_STATE;
       default:
         return state;
     }
   };